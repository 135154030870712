<template>
    <v-container fill-height fluid text-xs-center>
        <v-card class="fill-height container--fluid" width="100vw" flat outlined>
            <v-data-table :headers="headers" :items="municipalities" :items-per-page="-1" :search="searchCity"
                :loading="loadingData" item-key="id" sort-by="id" disable-sort
                no-data-text="No hay datos para mostrar..." loading-text="Cargando los datos, por favor espere!"
                class="elevation-1" :footer-props="{
                    showFirstLastPage: false,
                    disableItemsPerPage: false,
                    itemsPerPageAllText: 'Todas',
                    itemsPerPageText: 'Filas por página:'
                }">
                <template v-slot:top>
                    <v-toolbar flat class="align-center">
                        <v-toolbar-title class="text-subtitle-1 text-md-h5">Municipios</v-toolbar-title>
                        <v-divider class="mx-4" inset vertical></v-divider>
                        <v-spacer></v-spacer>
                        <v-responsive max-width="300" height="45" class="hidden-sm-and-down">
                            <v-text-field id="findtext-city" label="Buscar.." name="findcity" outlined dense single-line
                                clearable background-color="white" hide-details v-model="searchCity"
                                class="white--text mt-0 pt-0 mr-2" prepend-inner-icon="mdi-magnify">
                            </v-text-field>
                        </v-responsive>
                        <v-dialog :fullscreen="$vuetify.breakpoint.smAndDown" v-model="dialog" max-width="500px"
                            persistent>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn :small="$vuetify.breakpoint.smAndDown" color="blue darken-1" dark depressed
                                    elevation="0" class="mb-2" v-bind="attrs" v-on="on" @click="newItem">
                                    Adicionar
                                </v-btn>
                            </template>
                            <v-card>
                                <v-form ref="form" v-model="valid" lazy-validation>
                                    <v-card-title class="text-h6 grey lighten-2">
                                        <span class="headline">{{ formTitle }}</span>
                                    </v-card-title>
                                    <v-divider></v-divider>
                                    <v-card-text>
                                        <v-container>
                                            <v-row>
                                                <v-col cols="12">
                                                    <v-text-field v-model="editedItem.name" label="Nombre"
                                                        :rules="nameRules" required
                                                        :disabled="saveChanges"></v-text-field>
                                                </v-col>
                                            </v-row>
                                        </v-container>

                                    </v-card-text>
                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn color="primary" text :disabled="saveChanges" @click="close">
                                            Cancelar
                                        </v-btn>
                                        <v-btn :disabled="!valid" color="primary" submit :loading="saveChanges"
                                            @click="save">
                                            Guardar
                                        </v-btn>
                                    </v-card-actions>
                                </v-form>
                            </v-card>
                        </v-dialog>
                        <v-dialog v-model="dialogDelete" max-width="500px">
                            <v-card>
                                <v-card-title class="text-h6 headline">¿Est&aacute; seguro que desea eliminar este
                                    elemento?
                                </v-card-title>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn color="blue darken-1" :disabled="saveChanges" text
                                        @click="closeDelete">Cancelar</v-btn>
                                    <v-btn color="blue darken-1" :loading="saveChanges"
                                        @click="deleteItemConfirm">Aceptar</v-btn>
                                    <v-spacer></v-spacer>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                    </v-toolbar>
                </template>
                <template v-slot:item.active="{ item }">
                    <v-simple-checkbox v-model="item.active" disabled></v-simple-checkbox>
                </template>
                <template v-slot:item.actions="{ item }">
                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon v-bind="attrs" v-on="on" small class="mr-2" @click="editItem(item)">
                                mdi-pencil
                            </v-icon>
                        </template>
                        <span>Editar</span>
                    </v-tooltip>
                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon v-bind="attrs" v-on="on" small class="mr-2" @click="deleteItem(item)">
                                mdi-delete
                            </v-icon>
                        </template>
                        <span>Eliminar</span>
                    </v-tooltip>
                </template>
                <template v-slot:no-data>
                    <span class="text-h5">¡No hay registros en el listado!</span>
                    <br />
                    <v-btn color="primary" @click="getCities">
                        Recargar
                    </v-btn>
                </template>
            </v-data-table>
            <v-snackbar v-model="snackBar" :timeout="2000">
                {{ snackText }}
                <template v-slot:action="{ attrs }">
                    <v-btn v-bind="attrs" icon @click="snackBar = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </template>
            </v-snackbar>
        </v-card>
    </v-container>
</template>

<script>
import cityService from '@/providers/CitiesService';
import { changeInformationSEO } from "@/providers/KeywordsServices";

export default {
    name: "MunicipalityComponent",
    data: () => ({
        snackBar: false,
        snackText: '',
        valid: true,
        searchCity: '',
        loadingData: false,
        dialog: false,
        dialogDelete: false,
        municipalities: [],
        headers: [
            { text: 'Id', value: 'id', align: ' d-none' },
            {
                text: 'Nombre',
                align: 'start',
                sortable: false,
                value: 'name',
            },
            { text: 'Acciones', value: 'actions', width: 100, sortable: false },
        ],
        editedIndex: -1,
        editedItem: {
            id: 0,
            name: '',
        },
        defaultItem: {
            id: 0,
            name: '',
        },
        nameRules: [
            v => !!v || 'El Nombre es obligatorio!'
            // v => (v && v.length >= 10) || 'El Nombre debe tener mas de un caracter!',
        ],
        saveChanges: false,
    }),
    computed: {
        formTitle() {
            return this.editedIndex === -1 ? 'Nuevo municipio' : 'Editar municipio'
        },
    },
    watch: {
        dialog(val) {
            val || this.close()
        },
        dialogDelete(val) {
            val || this.closeDelete()
        },
    },
    methods: {
        getMunicipalities() {
            this.loadingData = true;
            cityService.getRecord(this.$route.params.city).then(record => {
                this.municipalities = record.value.municipalities;
            }).catch(() => {
                this.snackText = 'Ha ocurrido un error!';
                this.snackBar = true;
            }).finally(() => {
                this.loadingData = false;
            });
        },
        newItem() {
            this.valid = false;
        },
        editItem(item) {
            this.editedIndex = this.municipalities.indexOf(item);
            this.editedItem = Object.assign({}, item);
            this.dialog = true;
        },

        deleteItem(item) {
            this.editedIndex = this.municipalities.indexOf(item);
            this.editedItem = Object.assign({}, item);
            this.dialogDelete = true;
        },

        deleteItemConfirm() {
            this.saveChanges = true;
            cityService.deleteRecordMunicipality(this.editedItem.id).then((resp) => {
                if (resp.data.success) {
                    this.getMunicipalities();
                    this.closeDelete();
                    this.snackText = 'Registro eliminado con éxito.';
                }
                else {
                    this.snackText = 'Ha ocurrido un error!';
                }
            }).catch(() => {
                this.snackText = 'Ha ocurrido un error!';
            }).finally(() => {
                this.snackBar = true;
                this.saveChanges = false;
            });
        },

        close() {
            this.dialog = false
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem);
                this.editedIndex = -1;
            });
        },

        closeDelete() {
            this.dialogDelete = false
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem);
                this.editedIndex = -1;
            })
        },

        save() {
            this.$refs.form.validate();
            if (this.$refs.form.validate(true)) {
                this.saveChanges = true;
                if (this.editedIndex > -1) {
                    cityService.updateRecordMunicipality(this.editedItem).then((resp) => {
                        if (resp.data.success) {
                            this.close();
                            this.snackText = 'Datos del formulario guardado con éxito...';
                            this.getMunicipalities();
                        }
                        else {
                            this.snackText = 'Ha ocurrido un error';
                        }
                    }).catch(() => {
                        this.snackText = 'Ha ocurrido un error!';
                    }).finally(() => {
                        this.snackBar = true;
                        this.saveChanges = false;
                    });

                } else {
                    cityService.addRecordMunicipality({ id: this.$route.params.city, name: this.editedItem.name }).then((resp) => {
                        if (resp.data.success) {
                            this.close();
                            this.snackText = 'Datos del formulario guardado con éxito...';
                            this.getMunicipalities();
                        }
                        else {
                            this.snackText = 'Ha ocurrido un error!';
                        }
                    }).catch(() => {
                        this.snackText = 'Ha ocurrido un error!';
                    }).finally(() => {
                        this.snackBar = true;
                        this.saveChanges = false;
                    });
                }
            }
        },
    },
    mounted() {
        this.municipalities = [];
        this.getMunicipalities();
        changeInformationSEO();
    }
}
</script>

<style scoped>
.v-btn {
    text-transform: none !important;
}
</style>