<template>
    <MunicipalityComponent></MunicipalityComponent>
</template>

<script>
import MunicipalityComponent from "@/components/admin/catalogs/MunicipalityComponent";
export default {
    name: "MunicipalityView",
    title: "Gestión de Municipios | Baja California Health Tourism",
    components: { MunicipalityComponent },
};
</script>

<style scoped></style>
